@import '../../styles/globalimports.scss';

.skills{
    &__overlay{
        display:flex;
        margin-top:40px;
        width:100%;;
        background:$terminal-background;
        margin-bottom:150px;

        @include tablet{
            margin-bottom:0;
        }
        @include desktop{
            margin-bottom:150px;
        }
        @include wide-screen{
            margin-bottom:0;
        }
    }
    &__list{
        @include wide-screen{
            margin-left:10%;
            width: 76%;
        }
    }
    &__title{
        color:$terminal-object;
        font-size:13px;
        margin-bottom:20px;
        width:100%;
        font-family:"Consola";
        &-keyword{
           color:$terminal-keyword; 
        }
        @include tablet{
            font-size:26px;
            margin-left:20px;
            margin-bottom:40px;
        }
        @include desktop{
            font-size:35px;
        }
    }
    &__level{
        font-family:"Consola", sans-serif;
        &-terminal{
            margin-top:10px;
            @include tablet{
                margin-left:20px;
            }
            &-text{
                padding-top:5px;
                margin-bottom:10px;
                border-top:1px solid grey;
                color:grey;
                font-size: 13px;
                @include tablet{
                    font-size:16px;
                }
                
            }
        }
        &-output{
            z-index:5;
            visibility:hidden;
            color:$terminal-output;
            font-weight:bold;
            margin-bottom:50px;
            line-height:1.6;

            @include tablet{
                margin-left:10px;
                top:50px;
                font-size:20px;
            }
            @include desktop{
                font-size:30px;
            }
            &--proficient{
                @extend .skills__level-output;
                visibility: visible;
                animation: showElement 1.1s linear;
            }
            &--competent{
                @extend .skills__level-output;
                visibility: visible;
                animation: showElement 2.2s linear;
            }
            &--introduced{
                @extend .skills__level-output;
                visibility: visible;
                animation: showElement 3.1s linear;
            }
        }
        &-title{
            display:flex;
            
            color:$terminal-object;
            font-size:16px;
            margin:30px 0 5px 0;
     
            @include tablet{
                font-size:24px;
                margin-left:20px;
            }
            @include desktop{
                font-size:30px;
            }
            &-method{
                color:$terminal-method;
                white-space: nowrap;
                overflow: hidden;
                width:139px;
                // visibility:hidden;
                @include tablet{
                    width:207px;
                }
                @include desktop{
                    width:258px;
                }
            }
        }
    }
    &__cursor{
        animation: blink 1s step-end infinite;
    }
}

.terminal--underline{
    text-decoration:underline;
    color:white;
}
