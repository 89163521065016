@import '../../styles/globalimports.scss';

.skills{
    background: rgb(0,0,0);
    padding-bottom:100vw;
    
    @include tablet{
        padding-bottom:40vw;
        align-items:center;
    }

    @include desktop{
        padding-bottom:18vw;
    }
    
    @include wide-screen{
        padding-bottom:20vw;
    }

    &__pack{
        align-self:flex-start;
        width:200px;
        display:flex;
        flex-direction:column;
        align-items:flex-end;
        position:relative;
    }

    &__main{
        display:flex;
        flex-direction:column;
        margin-top:90px;
        margin-right:40px;
        justify-content:center;
        align-items:center;
   
        @include tablet{
            margin-top:100px;
        }
    }

    &__unpacked{
        width:50%;
        text-align:center;
        color:white;
        margin-left:58px;
        align-self:flex-start;
    }

    &__packed{
        position:absolute;
        top:528px;
        width:100%;
        text-align:center;
        font-size:32px;
        z-index:1;
        background-color:rgba(0,0,0,0.5);
        animation:text-on 1s ease-in, pulsate 2s ease-out, colorChange 2s linear infinite;

        @include tablet{
            top:59%;
            left:35%;
            width:40%;
            font-size:40px;
        }
        @include desktop{
            top:67%;
            left:38%;
            width:30%;
        }
        @include wide-screen{
            top:59%;
            left:36%;
            width:30%;
        }
        &-start{
            @extend .skills__packed;
            animation:colorChange 2s linear infinite;
            &--dnd{
                font-size:18px;
            }
        }        
    }

    &__hand{
        width:40px;
        height:30px;
        position:absolute;
        top:24%;
        left:36%;;
        z-index:20;
        animation:moveHand 1s 5s linear infinite;
        offset-path: path('M0,0 C100, 0 0,  0, 20');
    }
}

