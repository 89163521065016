 @import '../../styles/globalimports.scss';

.full-text{
    position:absolute;
    display:none;
    padding:16px;
    box-sizing:border-box;
    margin-bottom:200px;

    &--on{
        @extend .full-text;
        z-index:1;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:space-between;
       
        animation:fade-in 3s;
    }
}

.close__overlay{
    @include open-close-overlay();
    
    border-top-left-radius:100%;
    border-bottom-left-radius:100%;
    background-color:rgba(250,250,250,1);
    position:sticky;
    margin-left: 92.5vw;

    @include tablet{
        margin-left: 94.5vw;
    }
    @include desktop{
        margin-left: 95.5vw;
    }
    @include wide-screen{
        margin-left: 96.5vw;
    }
    
    &-icon{
        filter:$accent-filter;
        transform:rotate(90deg);
        margin-right:22px;
        @include desktop{
            margin-right:40px;
        }
    }
    
    &--off{
        display:none;
    }
}

