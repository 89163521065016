@import '../../styles/globalimports.scss';

.backpack{
    max-height:410px;
    max-width:410px;
    width:70%;

    @include tablet{
        width:100%;
        max-height:410px;
        max-width:410px;
    }
}