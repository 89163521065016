@import '../../styles/globalimports.scss';

.contact{
    background-color:black;
    color:white;
    background-size:cover;
    background-position:bottom right;
    box-sizing:border-box;
  
    &__content{
        padding:0 5%;
        text-align:center;
        &-quote{
            font-family:"Montserrat";
            margin:10px 0;
            color:silver;
            font-size:16px;
            text-align: center;

            @include colorWaveAnimation(5s);
            
            @include tablet{
                font-size:23px;
                line-height:1.5;
            }
            @include desktop{
                width:70%;
                margin:40px auto;
                font-size:28px;
            }
        }

        &-author{
            color:grey;
            text-align:right;
            margin-bottom:45px;
            width:100%;
            @include tablet{
                margin-bottom:40px;
                font-size:20px;
            }
            @include desktop{
                margin-top:20px;
                width:85%;
            }
        }

        &-cta{
            font-size:27px;
            margin-bottom:45px;
            
            @include tablet{
                font-size:40px;
                margin-bottom:75px;
            }
            @include desktop{
                margin-bottom:50px;
            }
        }
    }
    &__links{
        display:flex;
        justify-content:space-between;
        align-items:center;
        margin-bottom:48px;
        
        @include tablet{
            // margin-bottom:50px;
        }
        @include desktop{
            justify-content:center;
            margin-bottom:35px;
        }
        
        &-icon{
            visibility:hidden;
            width:50px;
            @include tablet{
				width:100px;
			}
            @include desktop{
                margin:50px;
            }
            &:hover{
                filter:$accent-filter;
                animation: bounce 0.5s infinite; 
            }
        } 
    }
}