@import '../../styles/globalimports.scss';

.about__overlay{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index:20;
    padding:20px 4% 0 4%;
    margin-bottom:200px;
    @include tablet{
        padding:20px 5% 0 5%;
        margin-bottom:0;
    }

    @include desktop{
        padding:20px 10% 0 10%;
        margin-bottom:200px;
    }
    @include wide-screen{
        margin-bottom:0;
    }
    &-title{
        font-size:16px;
        margin-bottom:16px;
        white-space: nowrap;
        overflow: hidden;
        width:100%;
       
        @include tablet{
            font-size:20px;
            width:278px;
        }
    }

    &-text{
        font-size:14px;
        margin-bottom:25px;
        line-height:1.5;
        color:#242321;

        @include tablet{
            font-size:16px;
        }
        @include desktop{
            font-size:20px;
        }
        &--emphasize{
            font-weight:bold;
            color:$text-accent;
        }
    }

    &-image{

        margin-bottom:25px;
        @include tablet{
            width:60%;
        }
        @include desktop{
            width:40%;
        }

    }
}