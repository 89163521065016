/*======================= Media Breakpoints ======================= */

// Changes to tablet format >=768px
@mixin tablet{
    @media (min-width:768px){
        @content;
    }
}

// Changes to desktop format >=1200px
@mixin desktop{
    @media (min-width:1280px){
        @content;
    }
}

// Changes to desktop format >=1700px
@mixin wide-screen{
    @media (min-width:1700px){
        @content;
    }
}

//Creates a div with default styling for a trail icon with specific top and left positioning for each device size
@mixin buildTrailDiv($top-mobile, $left-mobile, $top-tablet, $left-tablet, $top-desktop, $left-desktop){
    // border:1px solid black;
    top:$top-mobile;
    left:$left-mobile;
    width:18%;
    height:18%;

    @include tablet{
        top:$top-tablet;
        left:$left-tablet;
        width:18%;
        height:18%;
    }
    @include desktop{
        top:$top-desktop;
        left:$left-desktop;
    }
}

//Creates a div with default styling for each of the skills icon with a specified top and left position for each device
@mixin buildSkillsIcon($top-mobile, $left-mobile, $top-tablet, $left-tablet, $top-desktop, $left-desktop){
    position:absolute;
    top:$top-mobile;
    left:$left-mobile;

    @include tablet{
        top:$top-tablet;
        left:$left-tablet;
    }
    @include desktop{
        top:$top-desktop;
        left:$left-desktop;
    }
}

//Builds buttons with consistent styling for project overlays
@mixin buildProjectButton{
    visibility:hidden;
    background-color:black;
    border:3px solid silver;
    color:silver;
    padding:10px 20px;
    bottom:-100px;
    cursor:pointer;

    &:focus{
        outline:none;
    }
}

@mixin colorWaveAnimation($speed){
    background: linear-gradient(315deg, #FFF 20%, #d2c9c2 40%, $text-accent 60%, #FFF 80%);
    background-size: 200% auto;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: colorWave $speed linear infinite;
}

@mixin bottomContactIcon(){
    position:absolute;
    width:50px;
    top:0;
    left:-74vw;
    animation: moveIcon 0.75s linear, bounce 2s;
 
    @include tablet{
        width:75px;
    }
    @include desktop{
        width:100px;
        left:-60vw;
        top:0;
    }
}

@mixin open-close-overlay(){
    position:absolute;
    top:45%;
    z-index:10;
    border-top-right-radius:100%;
    border-bottom-right-radius:100%;
    background-color:rgba(250,250,250,0.3);
    height:50px;
    width:50px;
    display:flex;
    justify-content: center;
    align-items: center;

    @include tablet{
        height:75px;
        width:75px;
    }

    @include desktop{
        height:100px;
        width:100px;
    }
    
    &-icon{
        width:20px;
        height:20px;
        cursor:pointer;
        transform:rotate(270deg);
        @include tablet{
            width:30px;
            height:30px;
        }
    }
    &:hover{
        transition: all 0.5s;
        background-color:rgba(250,250,250,0.5);
        transform:scale(1.2);
    }
}
