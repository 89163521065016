@import './globalimports.scss';

// Remove the default padding and margins from all elements on page
*{
	padding:0px;
	margin:0px;
	scroll-behavior: smooth;
}

// Remove the underlines from all links on page and base color of white
a{
	text-decoration:none;
	color:white;
}

//set the default font for anything in the body
body{
	font-family:"SourceSansPro", sans-serif;
	font-weight:400;
}

//Remove the bullet points from all list elements
ul{
	list-style-type:none;	
}

h1{
	font-family:"Montserrat", sans-serif;
	letter-spacing:1px;
	font-size:20px;
	text-align:center;
	color:#d2c9c2;
}

//Set defaults for text for all of the overlays
#about__full-text, #skills__full-text, #experience__full-text{
    box-sizing:border-box; 
    width: 100%;

}

//Used to emphasize any important text
.emphasize{
    color:$text-accent;
    font-weight:bold;
}

//Applied to any element with a typing effect
.animate-type{
	//used for about overlay
    animation: type 1.5s 0.25s steps(60, end); 
    
	//these three are used for the skills overlay to offset timing
	&--first{
      visibility:visible;
      animation: type 0.6s 0.25s steps(60, end); 
    }
    &--second{
      visibility:visible;
      animation: showElement 1.4s linear, type 0.6s 1.3s steps(60, end); 
    }
    &--third{
      visibility:visible;
      animation: showElement 2.4s linear, type 0.6s 2.3s steps(60, end); 
    }
}

//Removes scrolling ability - video modal
.scroll-lock{
  	overflow:hidden;
}

//working on preview for dnd touch
.dnd{
	width:100px;
	height:100px;
}


//global styling for all 3 overlays
.white-overlay{
    position:absolute;
    //set to skills background color - skills is only section without a background image - will default to this color
    background-color:#1e1e1e; 
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    background-size:cover;
    width:0;
    min-height:100vh;
    z-index:100;

    transition: 0.2s ease;
    @include desktop{
        transition: 0.5s ease;
    }

      
    &--on{
		@extend .white-overlay;
        width:100%;
        overflow:auto;
        transition: 0.2s ease;
        @include desktop{
            transition: 0.5s ease;
        }
    }
    
}

.white-overlay__experience--on{
    @extend .white-overlay--on
}

.white-overlay__about--on{
    @extend .white-overlay--on;
}

.white-overlay__skills--on{
    @extend .white-overlay--on
}

.open-close-overlay{
    position:absolute;
    top:45%;
    left:-25px;
    z-index:10;
    border-top-right-radius:100%;
    border-bottom-right-radius:100%;
    background-color:rgba(250,250,250,0.3);
    height:50px;
    width:50px;
    display:flex;
    justify-content: center;
    align-items: center;

    @include desktop{
        left:-50px;
        height:100px;
        width:100px;
    }
    
    &-icon{
        margin-left:22px;
        width:20px;
        height:20px;
        cursor:pointer;
        transform:rotate(270deg);
        @include desktop{
            width:30px;
            height:30px;
            margin-left:40px;
        }
    }
    &:hover{
        transition: all 0.5s;
        background-color:rgba(250,250,250,0.5);
        transform:scale(1.2);
    }
}

