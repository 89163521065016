@import '../../styles/globalimports.scss';

main{
	height:100%;
}
.main__contacts{
    position:fixed;
    padding: 8px;
    background-color:silver;
    opacity:0.5;
	bottom: 0;
	right:0;

    width:37px;
    z-index:20;
    box-sizing:border-box;

    &--bottom{
        height:65px;
        background-color:transparent;
        @include tablet{
            height:109px;
        }
        @include desktop{
            height:146px;
        }
        @include wide-screen{
            height:160px;
        }
    }

   	@include tablet{
        padding: 10px;
        width:50px;
    }

    @include desktop{
        width:60px;
    }
    
    &-icon{
        &:hover{
            animation:bounce 0.5s linear;
        }
        &--bottom1{
            @include bottomContactIcon();
            offset-path: path('M0,0 C100, 0 -180,-200 -770,0');
        
            @include tablet{
                offset-path: path('M0,0 C100, 0 -180,-200 -1050,0');
            }
            @include desktop{
                offset-path: path('M0,0 C100, 0 -180,-210 -1400,0');
            }
            @include wide-screen{
                 offset-path: path('M0,0 C100, 0 -180,-210 -1700,0');
            }
        }
        &--bottom2{
            @include bottomContactIcon();
            left:-52vw;
            offset-path: path('M0,0 C100, 0 -180,-200 -720,0');
        
            @include tablet{
                offset-path: path('M0,0 C100, 0 -180,-200 -900,0');
            }
            @include desktop{
                left:-50vw;
                offset-path: path('M0,0 C100, 0 -180,-210 -1250,0');
            }
            @include wide-screen{
                offset-path: path('M0,0 C100, 0 -180,-210 -1500,0');
            }
        }

        &--bottom3{
            @include bottomContactIcon();
            left:-27vw;
            offset-path: path('M0,0 C100, 0 -180,-200 -650,0');
        
            @include tablet{
                left:-33vw;
                offset-path: path('M0,0 C100, 0 -180,-200 -750,0');
            }
            @include desktop{
                left:-40vw;
                offset-path: path('M0,0 C100, 0 -180,-210 -1100,0');
            }
            @include wide-screen{
                offset-path: path('M0,0 C100, 0 -180,-210 -1300,0');
            }
        }
        &--bottom4{
            @include bottomContactIcon();
            left:-6vw;
            offset-path: path('M0,0 C100, 0 -180,-200 -560,0');
        
            @include tablet{
                left:-15vw;
                offset-path: path('M0,0 C100, 0 -180,-200 -600,0');
            }
            @include desktop{
                left:-30vw;
                offset-path: path('M0,0 C100, 0 -180,-210 -1000,0');
            }
            @include wide-screen{
                offset-path: path('M0,0 C100, 0 -180,-210 -1100,0');
            }
        }
    }
}

