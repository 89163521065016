/** Color Palette **/

$text-accent:#3fb0ac;

$accent-filter: invert(61%) sepia(72%) saturate(335%) hue-rotate(129deg) brightness(86%) contrast(91%);

/** Skills Overlay **/
$terminal-keyword: #569CD6;
$terminal-object: #9CDCFE;
$terminal-background: #1e1e1e;
$terminal-output: #14ad72;
$terminal-method:#DCDCAA;

