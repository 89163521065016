@import '../../styles/globalimports.scss';

.section{
    width:100%;
    display:flex;
    flex-direction:column;
    position:relative;
    padding-top:151px;

}

#skills{
    overflow-x:hidden;
}

.open__overlay{
    @include open-close-overlay;
    left:-25px;
    
    @include tablet{
        left:-30px;
    }
    @include desktop{
        left:-50px;
    }
    
    &-icon{
        margin-left:22px;
        @include desktop{
            margin-left:40px;
        }
    }
}

