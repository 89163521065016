@import '../../styles/globalimports.scss';

.title{
    display:flex;
    flex-direction:column;
    align-items:center;
    margin-top:50px;
    z-index:1;

    @include tablet{
        margin-top:100px;
    }

    &-hide{
        display:none;
    }

    &-text{
        font-size:28px;
        margin-bottom:10px;
        @include tablet{
            top:3%;
        }
        @include desktop{
            font-size:35px;
        }
        
        &--contact{
            margin-top:40px;
            @include tablet{
                margin-top:70px;
            }
        }
    }
    &-down{
        width:40px;
        height:30px;
        z-index:1;
    }
}

// .arrow{

//     width: 25px;
//     height: 25px;
//     top: 50%;
//     margin: -20px 0 0 -20px;
//     transform: rotate(45deg);
//     border-left: none;
//     border-top: none;
//     border-right: 3px #fff solid;
//     border-bottom: 3px #fff solid;

//     &-wrapper{
//         position:relative;
//         margin-top:20px;
//         margin-bottom:20px;
//         cursor:pointer;
//         display:flex;
//         justify-content:center;
//         background-color:red;
        
//     }


//     &:before{
//         content: '';
//         width: 13px;
//         height: 13px;
//         top: 50%;
//         margin: -10px 0 0 -10px;
//         border-left: none;
//         border-top: none;
//         border-right: 1px #fff solid;
//         border-bottom: 2px #fff solid;
//         animation: arrow 2s infinite;
//         position:absolute;
//         left:50%;
//     }
   
// }

