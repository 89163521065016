/** ========= Montserrat - Titles and Large Text ===========**/
@font-face{
    font-family: "Montserrat";
    src:url("../../assets/fonts/Montserrat-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}

@font-face{
    font-family: "Montserrat";
    src:url("../../assets/fonts/Montserrat-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face{
    font-family: "Montserrat";
    src:url("../../assets/fonts/Montserrat-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
}

/** ========= SourceSansPro - General Default Text ===========**/

@font-face{
    font-family: "SourceSansPro";
    src:url("../../assets/fonts/SourceSansPro-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face{
    font-family: "SourceSansPro";
    src:url("../../assets/fonts/SourceSansPro-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}


/** ========= Consola - Skills Terminal ===========**/
@font-face{
    font-family: "Consola";
    src:url("../../assets/fonts/consola.ttf");
    font-weight: 400;
    font-style: normal;
}

