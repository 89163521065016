@import '../../styles/globalimports.scss';

.header{
    background-color:black;
    width:100vw;
    // height:151px;
    height:145px;
    display:flex;
    flex-direction:column;
    align-items:center;
    overflow:hidden;
    position:fixed;
    top:0;
    left:0;
    z-index:101;
    box-sizing:border-box;
    box-shadow: 0px 0px 10px 0px rgba(silver, 1);

    @include tablet{
        height:177px;
    }

    &__logo{

        position:relative;
        width:216px;
        height:100px;
        margin-top:8px;
        font-family:"Montserrat";

        @include tablet{
            width:272px;
            margin-left:10px;
        }

        &-text{
            color:white;
            text-align:center;
            position:absolute;
            top:45px;
            left:28px;

            &:hover{
                @include colorWaveAnimation(2s);
            }

            @include tablet{
                top:56px;
                left:45px;
            }
        }
        &-name{
            font-weight:bold;
            letter-spacing:2px;
            @include tablet{
                font-size:18px;
            }
        }
        &-title{
            font-size:12px;
            letter-spacing:1px;
        }
    }

    .nav{
        width:100%;
        padding-top:10px;
        font-family: "SourceSansPro";
        display:flex;
        flex-direction:column;
        align-items:flex-end;

        @include tablet{
            display:flex;
            align-items:center;
            width:344px;
            // margin-right:10px;
        }

        &__hamburger{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25px;
            margin-right:3%;
            cursor: pointer;
            transition: all .5s ease-in-out;
            
            @include tablet{
                display:none;
            }
            &--open .nav__hamburger-icon {
                transform: translateX(-50px);
                background: transparent;
                box-shadow: none;
            }
            &--open .nav__hamburger-icon::before {
                transform: rotate(45deg) translate(30px, -40px);
            }
            &--open .nav__hamburger-icon::after {
                transform: rotate(-45deg) translate(30px, 40px);
            } 
            &-icon{
                width: 100%;
                height: 2px;
                background: #fff;
                border-radius: 5px;
                transition: all .5s ease-in-out;

                &::before,
                &::after {
                content: '';
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background: #fff;
                    border-radius: 5px;
                    transition: all .5s ease-in-out;
                }
                &::before {
                    top:7px;
                    transform: translateY(-16px);
                }
                &::after {
                    bottom:7px;
                    transform: translateY(16px);
                }
            }
        }
        &__list{
            display:flex;
            flex-direction:column;
            justify-content:space-between;
            box-sizing:border-box;
            padding: 5px;
            text-align:center;
            background-color:black;
            width:50%;
            height:180px;
            position:fixed;
            top:145px;
            right:0;
            
            @include tablet{ 
                position:static; 
                height:auto;
                box-shadow:none;              
                width:100%;
                flex-direction:row;
                justify-content:center;
                padding: 23px 5px 0 5px;
            }
            &--on{
                @extend .nav__list;
                animation: menu-down 0.1s ease-in;    
            }
            &--off{
                height:0;
            }
        }
        &__link{
            color:#d2c9c2;
            padding:8px;
            font-size:14px;
            min-width:75px;

            @include tablet{
                font-size:16px;
            }

            &--active{
                @extend .nav__link;
                background-color:rgba(250,250,250,0.2);
                border-radius:4px;
                color:$text-accent;
                font-weight:bold;
            }
        
            &:hover{
                @extend .nav__link--active;
                color:white;
            }


        }
    }
}

