@import '../../styles/globalimports.scss';

.skills{
    &__icon{
        width:30%;
        height:auto;
        max-height:121px;
        max-width:90px;
        cursor:grab;
        
        @include tablet{
            width:20%;
            max-height:200px;
            max-width:120px;
        }
    }
    
    &__html-css{
        @include buildSkillsIcon(37%, 72%, 38%, 79%, 39%, 75%);
    }

    &__js{
        @include buildSkillsIcon(27%, 10%, 29%, 66%, 26%, 66%);
        width:15%;
        @include tablet{
            width:10%;
        }
        @include desktop{
            width:8%;
        }
        @include wide-screen{
            width:5%;
        }
    }
        
    &__node{        
        @include buildSkillsIcon(615px, 10%, 43%, 6%, 37%, 16%);
        
    }
    
    &__react{
        @include buildSkillsIcon(28%, 42%, 27%, 34%, 28%, 37%);
        width:18%;
        @include tablet{
            width:10%;
        }
        @include desktop{
            width:8%;
        }
         @include wide-screen{
            width:6%;
        }
    }

    &__sass{
        @include buildSkillsIcon(48%, 75%, 51%, 73%, 52%, 63%);
    }

    &__databases{
        @include buildSkillsIcon(42%, 3%, 55%, 13%, 66%, 21%);
    }

    &__axios{
        @include buildSkillsIcon(637px, 61%, 64%, 78%, 68%, 72%);//71%
    }
    
    &__git{
        @include buildSkillsIcon(27%, 78%, 32%, 17%, 49%, 29%); //72
        width:20%;
        @include tablet{
            width:12%;
        }
    }
}

// .animate-skill{
//     animation:moveHand 2s 5s linear;
//     offset-path: path('M0,0 C100, 0 50, 70 300, 20');
// }