@import '../../styles/globalimports.scss';

.trail{
    &-icon{
        min-width:44px;
        max-height:53px;
        max-width:72px;

        @include tablet{
            min-width:61px;
            max-height:72px;
            max-width:72px;
        } 

        
        @include desktop{
            min-width:96px;
            max-height:96px;
            max-width:96px;
        } 
    }

    &__div{
        position:absolute;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        &:hover{
            transform:scale(1.1);
            filter:grayscale(1);
        }

        &-head{
             @include buildTrailDiv(-2.5%, 5%, 0, 6%, -3%, 18%);
             z-index:1;
        }

        &-hiker{
            @include buildTrailDiv(9%, 29%, 9%, 29%, 6.5%, 34%);
            &:hover{
                filter:invert(90%) sepia(3%) saturate(22%) hue-rotate(322deg) brightness(85%) contrast(93%);
            }
        }
            
        &-detour{        
            @include buildTrailDiv(38%, 42%, 38%, 44%, 37%, 43%);
            &:hover{
                filter:invert(90%) sepia(3%) saturate(22%) hue-rotate(322deg) brightness(85%) contrast(93%);
            }
        }
        
        &-db{
            @include buildTrailDiv(61%, 10%, 60%, 10%, 60%, 21%);
        }
        
        &-dog{
            @include buildTrailDiv(43%, 75%, 43%, 77%, 43%, 64%);
        }

        &-end{
            @include buildTrailDiv(82%, 39%, 82%, 39%, 84%, 40%);
            &:hover{
                filter:invert(90%) sepia(3%) saturate(22%) hue-rotate(322deg) brightness(85%) contrast(93%);
            }
        }

    }
}

.trail-title{
    padding-top:5px;
    width:96px;
    font-size:12px;

    @include tablet{
        padding-top: 5px;
        width: 162px;
        font-size: 18px;
    }

    @include desktop{
        padding-top: 5px;
        width: 162px;
        font-size: 20px;
    }
}