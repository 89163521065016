@import '../../styles/globalimports.scss';

img{
    width:100%;
  }

.intro{
    position:relative;
    display:flex;
    justify-content:center;
    overflow:hidden;

    &--off{
        visibility:hidden;
    }

    &__loading{
        position:absolute;
        top:0;
        right:0;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        height:100vh;
        width:100vw;
        background-color:rgba(0,0,0,1);
        overflow:hidden;

        &-container{
            display:flex;
            align-items:center;
        }

        &-logo{
            // margin-bottom:30px;
        }
        &-img{
            width:100px;
        }
    }

    &__replay{
        position:absolute;
        filter: invert(61%) sepia(72%) saturate(335%) hue-rotate(129deg) brightness(86%) contrast(91%);
        width:30px;
        top:0;
        right:0;
        color:$text-accent;
        z-index:1;
        animation: fade-in 1s ease-in;
        margin: 5%;
        cursor:pointer;
        @include tablet{
            margin: 3%;
        }
        @include desktop{
            margin: 2%;
        }

    }

    &__skip{
        position:absolute;
        top:0;
        right:0;
        color:$text-accent;
        margin: 2%;;
        z-index:1;
        animation: fade-out 1s 5s ease-out;
        margin:5%;
        @include tablet{
            margin: 3%;
            font-size:18px;
        }
        @include desktop{
            margin: 2%;
            font-size:22px;
        }
    }

}



.logo{
    position:absolute;
    z-index:1;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:100px;
    color:white;
    font-family: "Montserrat", sans-serif;
    width:100%;

    @include tablet{
        margin-top:95px;
    }

    &-text{
        color:white;
    }

    &__name{
        letter-spacing:5px;
        font-size:24px;
        box-sizing: border-box;
        text-align:center;
        width:100%;
        font-weight:bold;

        @include colorWaveAnimation(5s);

        @include tablet{
            font-size:32px;
        }
    }

    &__title{
        font-size:18px;
        letter-spacing: 3px;
        text-align:center;
        animation: letterColor 7s ease-in;

        @include tablet{
            font-size:20px;
        }

    }
    &__filter{
        position:absolute;
        animation:logo-in 7s ease-in;
        top:-60px; 
        z-index:-1;
        opacity:0.5;
        width:79%;

        @include tablet{
            width:44vw;
            max-width:337px;
            max-height:146px;
            top:-72px;
        }
    } 
}

.enter{
    position:absolute;
    text-align:center;
    color:$text-accent;
    font-size:27px;
    font-family: "Montserrat", sans-serif;
    font-weight:600;
    top:43vh;
    z-index:1;
    animation: fade-in 1s ease-in;

    width: 100vw;
    text-align: center;
    padding-right: 3%;

    @include tablet{
        font-size:34px;
        top:42vh;
        // left:36vw;
        width:200px;
        padding-right: 1%;
    }
}

.video{
    display:none;
    object-fit:cover;
    width:100%;
    height:100vh;

    @include tablet{
        display:block;
    }
    &__link{
        width:100vw;
        height:100vw;
    }

    &-gif{
        @extend .video;
        display:block;
        @include tablet{
            display:none;
        }
    }
  }