@import '../../styles/globalimports.scss';

.trail-modal{
    animation: show-modal 0.2s linear;
    background-color: white;
    border-radius:20px;
    padding:35px;
    box-sizing: border-box;
    position:absolute;
    z-index:101;
    width:98%;
    top:22%;
    
    @include tablet{
        width:64vw;
        padding:53px;
    }

    @include desktop{
        width:40vw;
    }

    @include wide-screen{
        width:37vw;
    }

    &--left{
        @extend .trail-modal;
        left:0;

        @include tablet{
            left:17vw;
        }
        
        @include desktop{
            left:5vw;
        }
        
        @include wide-screen{
            left:15vw;
        }
    }
    &--right{
        @extend .trail-modal;
        
        left:0;

        @include tablet{
            left:17vw;
        }
        
        @include desktop{
            left:55vw;
        }
    }

    &__container{
        border-radius:20px;
        color:black;
    }
        
    &__experience{
        display:flex;
        flex-direction:column;
        margin-bottom: 30px;
        @include tablet{
            width:100%;
            margin-bottom:0;
        }

    }

    &__place{
        width:100%;
        box-sizing:border-box;
        display:flex;
        align-items:center;
        margin-bottom:20px;
        @include tablet{
            align-items:center;
            margin-bottom:0;
        }
        &-circle{
            position:relative;
            width:80px;
            height:80px;
            border-radius:50%;
            border: 4px solid $text-accent;
            box-shadow: 2px 11px 32px  black;
            display:flex;
            justify-content:center;
            align-items:center;
            background-color:white;
            margin-right:20px;

            @include tablet{
                width:100px;
                height:100px;
                margin: 20px;

            }

            @include desktop{
                margin-right:50px;
            }
        }
        &-img{
            width:65%;
            @include tablet{
                width:60px;
            }
        }
        &-divider{
            border-left:2px solid black;
            width:10px;
            height:20px;
            margin-left:58%;
            @include tablet{
                display:none;
                height:44px;
                margin-left:0;
                transform:rotate(90deg);
            }
        }

        &-text{
            text-align:left;
            font-size:16px;
            width:50%;
            @include tablet{
                font-size:20px;
            }
        }
        &-date{
            font-weight:bold;
        }
        &-place{
            color:$text-accent;
            font-weight:bold;
        }
        &-position{
            width:100%;
            font-size:14px;
            margin-bottom:5px;
            @include tablet{
                font-size:16px;
            }
            @include desktop{
                font-size:20px;
            }                         
        }
    }
       
    &__description{
        margin-top:15px;
        width:100%;
        font-size:14px;
        text-align:left;
        @include tablet{
            font-size:18px;
            margin-bottom:20px;
        }
    }

    &-content{
        font-size:14px;
        color:black;
        @include tablet{
            font-size:16px;
        }
        @include tablet{
            font-size:18px;
        }
    }
    &-lesson{
        font-size:16px;
        font-weight:bold;
        color:$text-accent;
        margin:7px 0;
        @include tablet{
            font-size:18px;
        }
        @include desktop{
            font-size:20px;
            margin-top:12px;
        }                    
    }
}