@import '../../styles/globalimports.scss';

.experience{
    // height:136vh;
    background-size:cover;
    padding-bottom:40vw;

    @include desktop{
        padding-bottom:6vw;

    }
    
    @include wide-screen{
        padding-bottom:6vw;

    }

    &__content{
        width:100%;
        height:50%;
        overflow:visible;

        max-width:1000px;
        
        position:relative;
        max-width:414px;
        display:flex;
        justify-content:center;
        margin:0 auto;
        @include tablet{
            width:78%;
            height:80%;  
            max-width:722px;
        }
        @include desktop{
            max-width:1092px;
        }
        
        box-sizing:border-box;
        padding:42px;
        // margin-top:20px;

        
        font-size:12px;
        color:white;
        text-align:center;
        overflow: visible;
        
        @include tablet{
            padding:75px;
        }
        @include desktop{
            // padding:162px;
        }
    }

}

.trail__image{
    @include desktop{
        width:55%;
    }
}

.transition2{
    background: linear-gradient(rgba(0, 0, 0,1), rgba(0,0,0,0));
    width:100%;
    height:31vh;
    position:absolute;
    top:0;
}

.transition3{
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0,0,0,1));
    height:100vh;
    position: absolute;
    width:100%;
    bottom:0;
    height:10vh;
}

