@import '../../styles/globalimports.scss';

.projects{
    background-color:black;
    // padding-bottom:50vw;
    padding-bottom:263px;
    overflow:hidden;

    @include tablet{
        padding-bottom:302px;
    }

    @include desktop{
        padding-bottom:27vw;
    }

    &__mountain{
        position:absolute;
        bottom:0;
        left:10%;
        min-width:480px;
        min-height:289px;
        z-index:6;
        
        @include tablet{
            left:57%;
            width:75%;
        }

        @include desktop{
            width:50%;
            left:62%;
        }
    }

    &__nav{
        color:white;
        display:flex;
        justify-content:center;
        padding:7px 20% 0 20%;;

        @include tablet{
            display:none;
        }
        &-link{
            padding:7px 15px;
            list-style-type: none;
            &--active{
                background-color:rgba(250,250,250,0.2);
                border-radius:4px;
                color:$text-accent;
                font-weight:bold;            
                padding:7px 15px;
            }
        }
    }
    
    &__content{
        display:flex;
        flex-direction:column;
    }
    
    &__tech{
        display:flex;
        justify-content: space-between;
        background-color:white;
        border-radius:4px;
        padding:5px;
        margin: 10px 0;
        &-icon{
            width:35px;
            &--sass{
                @extend .projects__tech-icon;
                width: 45px;
            }
        }
    }

    &__list{
        display:flex;
        flex-direction:column;
        align-items:center;
        margin-top:40px;
        @include tablet{
            flex-direction:row;
            justify-content:center;
        }

        &-description{
            visibility:hidden;
            position:absolute;
            font-size:14px;
            color:silver;
            background: rgba(0,0,0,0.8);
            top:0;
            bottom:0;
            padding:3%;

            @include tablet{
                font-size:16px;
            }

            @include desktop{
                font-size:24px;
            }

            &-pitch{
                text-align:center;
                margin-bottom:10px;
                @include tablet{
                    margin-bottom:10px;
                }
                @include desktop{
                    margin-bottom:30px;
                }
            }
            &-users{
                text-align:center;
                font-size:14px;
                margin-bottom:15px;
                @include desktop{
                    font-size:18px;
                }
            }
        }

        &-img{
            height:100%;
            &--brainflix{
                width:100%;
                height:100%;
                object-fit:contain;
            }
        }

        &-first{
            @include buildProjectButton();
            z-index:7;
        }
        
        &-first--brainflix{
            @include buildProjectButton();
            z-index:7;
        }

        &-second{
            @include buildProjectButton();
            z-index:7;
        }
        &-second--brainflix{
            @include buildProjectButton();
            z-index:7;
        }

        &-third{
            @include buildProjectButton();
            z-index:7;
        }

        &-project{
            &--p2t{
                position:relative;
                z-index:6;

                &:hover .projects__list-description{
                    visibility:visible;
                    animation:showElement 0.5s;
                    z-index:6;
                }

                &:hover .projects__list-first{
                    visibility:visible;
                    position:absolute;
                    animation:showElement 0.5s, dropButtons 0.5s 0.5s linear, bounce 1s 0.5s;
                    left:1%;
                    bottom:5%;

                    @include tablet{
                        left:3%;
                    }
                    
                    @include desktop{
                        left:15%;
                    }
                }

                //second button for p2t hover
                &:hover .projects__list-second{
                    visibility:visible;
                    position:absolute;
                    right:38.5%;
                    bottom:5%;
                    animation:showElement 0.6s, dropButtons 0.5s 0.6s linear, bounce 1s 0.6s;
                    @include desktop{
                        right:42%;
                    }
                }
            

                &:hover .projects__list-third{
                    visibility:visible;
                    position:absolute;
                    right:1%;
                    bottom:5%;
                    animation:showElement 0.7s, dropButtons 0.5s 0.7s linear, bounce 1s 0.7s;
                    
                    @include tablet{
                        right:3%;
                    }
                    
                    @include desktop{
                        right:15%;
                    }
                }

                &:hover::before{
                    width:50%;
                    transition: width opacity;
                    transition-duration: 0.5s;
                }
                &:hover::after{
                    width:50%;
                    transition: width opacity;
                    transition-duration: 0.5s;
                }
                &::before{
                    position:absolute;
                    content:'';
                    opacity:0.6;
                    width:0%;
                    height:100%;
                    left:0;
                    background-color:silver;
                }

                &::after{
                    position:absolute;
                    content:'';
                    position:absolute;
                    opacity:0.6;
                    width:0px;
                    height:100%;
                    right:0;
                    top:0;
                    background-color:silver;
                }

                width:90%;
                margin-bottom: 20px;

                @include tablet{
                    width:40%;
                    height:29vw;
                    max-height:270px;
                    max-width:356px;
                }
                @include desktop{
                    max-height:348px;
                    max-width:480px;
                }
            }
            &--brainflix{
                @extend .projects__list-project--p2t;

                @include tablet{
                    margin-left:20px;
                }

                &:hover .projects__list-first--brainflix{
                    visibility:visible;
                    position:absolute;
                    animation:showElement 0.5s, dropButtons 0.5s 0.5s linear, bounce 1s 0.5s;
                    width:95px;
                    left:1%;
                    bottom:7%;

                    @include tablet{
                        left:3%;
                    }
                    
                    @include desktop{
                        left:15%;
                    }
                }

                &:hover .projects__list-second--brainflix{
                    visibility:visible;
                    position:absolute;
                    width:95px;
                    right:38.5%;
                    bottom:7%;
                    animation:showElement 0.6s, dropButtons 0.5s 0.6s linear, bounce 1s 0.6s;
                    @include tablet{
                        right:31%;
                    }
                    @include desktop{
                        right:42%;
                    }
                }
            }
            
        }
    }
}

.transition4{
    position:absolute;
    width:100%;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0,0,0,1));
    height:100px;
    z-index:7;
    bottom:-11px;
    
}

.modal{
    z-index:21;
    position:relative;
    display:flex;
    justify-content:center;
    height:30%;
    box-sizing:border-box;
    margin-top: 20px;
    padding:3%;
    width:100%;

    @include tablet{
        height:55%;
        margin-top: 20px;
    }

    @include desktop{
        height:80%;
        margin-top: 0;
    }

    &__close{
        position:absolute;
        top:2%;
        right:1%;
        width:30px;
        z-index:21;
        filter: invert(96%) sepia(2%) saturate(1209%) hue-rotate(333deg) brightness(88%) contrast(85%);
        @include tablet{
            right:3%;
        }
    }
    &__video{
        width:100%;
        @include tablet{
            width:90%;
            height:97%; 
        }
    }
    &__container{
        width:100vw;
        height:100vh;
        background-color: rgba(0,0,0,0.9);
        color: #d2c9c2;
        position:absolute;
        z-index:20;
        display:flex;
        justify-content:center;
    }
}
