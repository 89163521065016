@import '../../styles/globalimports.scss';

.about{
    display:flex;
    background-size:cover;
    background-position:center;
    @include tablet{
        padding-bottom:0;
        background-position:bottom;
    }

    &__content{
        width:90%;
        align-self:center;
        height:100%;

        @include desktop{
            width:65%;
        }
    }

    &__tagline{
        color:silver;
        text-align:center;
        margin:40px 0;
        padding:10px;
        @include tablet{
            margin:80px 0;
        }
        @include desktop{
            font-size:40px;
            margin-top:75px;
        }
    }

    &__journey{
        // margin-top:50px 0 407px 0;
        margin-top:50px;
        margin-bottom: 140px;
        text-align:center;
        font-size:20px;
        @include tablet{
            font-size:25px;
            margin-bottom:312px;
        }
        @include desktop{
            margin-top:26vh;
            font-size:25px;
            font-size:40px;
            margin-bottom:290px;
        }
        &-link{
            position: relative;
            display: inline-block;
            overflow: hidden;
            background: linear-gradient(to right, $text-accent, $text-accent 50%, white 50%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-size: 200% 100%;
            background-position: 100%;
            transition: background-position 275ms ease;

            &:hover{
                background-position: 0 100%;

            }
        }
    }
}

.transition1{
    background: linear-gradient(rgba(31, 24, 27,0), rgba(0,0,0,1));
    // height:208px;
    height:84px;
    @include tablet{
        height:200px;
    }
    @include desktop{
        // height:120px;
    }
}

//useed to preload the consola text for skills overlay
.preloader{
    display:none;
    font-family:'consola', open-sans;
}